import React, { useEffect } from 'react';
import Icon from '../../../components/Icon';
import { formatNumberWithCommas } from '../../../utils/helpers';
import PassCard from '../../../components/PassCard';
import { useDispatch, useSelector } from 'react-redux';
import {
	getResellablePass,
	getRessellersClientsStats,
} from '../../../features/resellers/resellersSlice';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
	const { userDetails } = useSelector((store) => store.user);
	const {
		resellerPasses,
		// isLoading,
		resellerClientsStats,
	} = useSelector((store) => store.resellers);
	const dispatch = useDispatch();
	useEffect(() => {
		if (userDetails?.reseller) {
			Promise.all([
				dispatch(getResellablePass()),
				dispatch(getRessellersClientsStats()),
			]);
		} else {
			navigate('/become-partner');
		}
	}, []);
	const navigate = useNavigate();
	const getUniqueTiers = () => {
		const allTiers = resellerPasses.flatMap((plan) =>
			plan?.supportedTiers?.map((tierObj) => tierObj?.tier)
		);
		return [...new Set(allTiers)];
	};

	return (
		<div className="dashboard">
			<div className="dasboard__header">
				<h1 className="dashboard__title">Passes Expiring</h1>
				<div className="dashboard__header--container">
					<div className="iconCircle">
						<span className="">
							<Icon width={30} height={30} id={'personTalk'} />
						</span>
					</div>

					<div className="dashboard__header--cards">
						<div className="card">
							<div className="daysToExpiriy">
								<span className="critical">Today</span>
								<p>
									{formatNumberWithCommas(
										resellerClientsStats?.expiringTodayCount
									)}
								</p>
							</div>
						</div>
						<div className="card">
							<div className="daysToExpiriy">
								<span className="warning">This Week</span>
								<p>
									{formatNumberWithCommas(
										resellerClientsStats?.expiringThisWeekCount
									)}
								</p>
							</div>
						</div>
						<div className="card">
							<div className="daysToExpiriy">
								<span className="expired">Expired</span>
								<p>
									{formatNumberWithCommas(resellerClientsStats?.expiredCount)}
								</p>
							</div>
						</div>
					</div>

					<p
						className="dashboard__textLink"
						onClick={() => {
							navigate('/clients-renew');
						}}
					>
						{' '}
						See Clients {'>'}
					</p>
				</div>
			</div>
			<div className="dashboard__body">
				<div className="dashboard__body--header">
					<h1 className="dashboard__title">Passes </h1>
					{getUniqueTiers()?.length > 0 && (
						<p
							className="dashboard__textLink"
							onClick={() =>
								navigate(`/gym-coverage/${getUniqueTiers().join(',')}`)
							}
						>
							{' '}
							SEE COVERAGE LIST {'>'}
						</p>
					)}
				</div>
				<div className="dashboard__body--cards">
					{resellerPasses?.map((pass, index) => (
						<PassCard pass={pass} key={index} />
					))}
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
