import React from 'react';
import { Modal } from '../Modal';
import Input from '../forms/Input';
import Button from '../Button';

const UpdateResellersClientModal = ({
	updateRenewalModal,
	setUpdateRenewalModal,
	setDate,
	today,
	date,
	handleRenew,
	isLoading,
}) => {
	return (
		<Modal
			title="Update Exp. Date"
			show={updateRenewalModal}
			handleClose={() => setUpdateRenewalModal(false)}
		>
			<Input
				type="date"
				id="expiryDate"
				name="expiryDate"
				onChange={(e) => setDate(e.target.value)}
				isFloatingLabel={false}
				min={today}
				value={date}
			/>
			<Button
				variant={'primary'}
				size={'md'}
				block={true}
				onClick={handleRenew}
			>
				{' '}
				{isLoading ? 'Saviing...' : '	Save Exp. Date'}
			</Button>
		</Modal>
	);
};

export default UpdateResellersClientModal;
