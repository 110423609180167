import React, { useState } from 'react';
import Table from './Table';
import { dateFormat } from '../../utils/helpers';
import Icon from '../Icon';
import Button from '../Button';

const ClientRenewalTable = ({
	data,
	handleCheck,
	handleCheckAll,
	setUpdateRenewalModal,
	setSelectedClient,
	checkAll,
}) => {
	const [openDropdownId, setOpenDropdownId] = useState(null);
	const columns = [
		'check',
		'Name',
		'Label',
		'Client Expiry Date',
		'Renewal Status',
		'Action',
	];
	return (
		<Table
			columns={columns}
			blueHeader={true}
			hasCheckBoxHeader={true}
			handleCheckAll={handleCheckAll}
			checkAll={checkAll}
		>
			<>
				{data?.map((row) => (
					<tr className="pointer grey-bg" key={row?.accessCode}>
						{' '}
						<td className="plan-table">
							<input
								type="checkbox"
								className="check"
								checked={row.isChecked}
								onChange={() => handleCheck(row?.accessCode)}
							/>
						</td>
						<td className="plan-table ">
							{row?.firstName + ' ' + row.lastName}
						</td>
						<td className="plan-table">
							<div className="label-container">
								{row?.label ? (
									(() => {
										const labels = row.label;
										return labels.length > 0 ? (
											<>
												<span className="label-field">{labels[0]}</span>
												{labels.length > 1 && (
													<>
														<span
															className="action-icon"
															onClick={(e) => {
																e.stopPropagation();
																setOpenDropdownId(
																	openDropdownId === row.id ? null : row.id
																);
															}}
														>
															<Icon width="16" height="16" id="down-caret-2" />
														</span>
														{openDropdownId === row.id && (
															<div className="label-dropdown">
																{labels.slice(1).map((label, index) => (
																	<div key={index} className="label-item">
																		{label}
																	</div>
																))}
															</div>
														)}
													</>
												)}
											</>
										) : (
											<span className="label-field">N/A</span>
										);
									})()
								) : (
									<span className="label-field">N/A</span>
								)}
							</div>
						</td>
						<td className="plan-table duration">
							{dateFormat(row?.expiryDate, 'do MMM, yyyy' || '--')}
						</td>
						<td className={`plan-table`}>
							<span
								className={`status ${
									row?.renewalStatus === 'Expired'
										? 'statusExpired'
										: row?.renewalStatus === 'Today'
										? 'statusCritical'
										: 'statusWarning'
								}`}
							>
								<span>
									<Icon
										width={13}
										height={13}
										id={'statusCircle'}
										className={
											row?.renewalStatus === 'Expired'
												? 'statusExpired'
												: row?.renewalStatus === 'Today'
												? 'statusCritical'
												: 'statusWarning'
										}
									/>
								</span>{' '}
								{row?.renewalStatus}
							</span>
						</td>
						<td className="plan-table">
							<Button
								variant={'secondary'}
								size={'sm'}
								className={'btnText'}
								onClick={() => {
									setUpdateRenewalModal(true);
									setSelectedClient(row);
								}}
							>
								Update Exp. Date
							</Button>
						</td>
					</tr>
				))}
			</>
		</Table>
	);
};

export default ClientRenewalTable;
