import React, { useEffect, useState } from 'react';
import Goback from '../../../components/Goback';
import ClientRenewalTable from '../../../components/table/ClientRenewalTable';
import { useDispatch, useSelector } from 'react-redux';
import {
	resellerClientByStatus,
	updateBulkClientExpiry,
	updateResellerDate,
	getAllResellerLabels,
	// updateResellerDate,
} from '../../../features/resellers/resellersSlice';
import PaginationWithApi from '../../../components/PaginationWithApi';
import UpdateResellersClientModal from '../../../components/modals/UpdateResellersClientModal';
import Icon from '../../../components/Icon';
import Spinner from '../../../components/PageLoader';
import NoRecords from '../../../assets/svgs/noRecords.svg';
// import SearchAddContainer from '../../../components/SearchAddContainer';

const ClientsRenew = () => {
	const dispatch = useDispatch();
	const { resellerClientsStatus, isLoading, loadSideModal, resellerLabels } =
		useSelector((store) => store.resellers);

	const [currentPage, setCurrentPage] = useState(1);
	const [statusCheck, setStatusCheck] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [updateRenewalModal, setUpdateRenewalModal] = useState(false);
	const today = new Date().toISOString().split('T')[0];
	const [date, setDate] = useState(today);
	const [selectedClient, setSelectedClient] = useState(null);
	const [openFilterList, setOpenFilterList] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState({});
	const [openLabelsList, setOpenLabelsList] = useState(false);
	const [selectedLabel, setSelectedLabel] = useState();

	useEffect(() => {
		dispatch(
			resellerClientByStatus({
				filterBy: selectedFilter?.key,
				label: selectedLabel,
			})
		);
		setCheckAll(false);
	}, [selectedFilter?.key, selectedLabel]);

	useEffect(() => {
		dispatch(getAllResellerLabels());
	}, []);

	useEffect(() => {
		setStatusCheck(
			resellerClientsStatus.map((item) => ({ ...item, isChecked: false }))
		);
	}, [resellerClientsStatus]);

	const handleCheck = (id) => {
		const updatedRows = statusCheck.map((row) =>
			row.accessCode === id ? { ...row, isChecked: !row.isChecked } : row
		);
		setStatusCheck(updatedRows);
		const allChecked = updatedRows.every((row) => row.isChecked);
		setCheckAll(allChecked);
	};

	const checkedCount = statusCheck.filter((row) => row.isChecked);

	const handleCheckAll = () => {
		const newCheckAll = !checkAll;
		setCheckAll(newCheckAll);
		const updatedRows = statusCheck.map((row) => ({
			...row,
			isChecked: newCheckAll,
		}));
		setStatusCheck(updatedRows);
	};

	const unCheckBoxes = () => {
		const updatedRows = statusCheck.map((row) => ({
			...row,
			isChecked: false,
		}));
		setStatusCheck(updatedRows);
	};
	const pageSize = 25; // Default page size

	const totalPages = Math.ceil(statusCheck.length / pageSize);

	const handlePrev = () => {
		if (currentPage === 1) return;
		setCurrentPage((p) => p - 1);
	};
	const handleNext = () => {
		if (currentPage === totalPages) return;
		setCurrentPage((p) => p + 1);
	};
	const paginatedItems = statusCheck.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	const filterOptions = [
		{ name: 'Today', key: 'TODAY' },
		{ name: 'Expired', key: 'EXPIRED' },
		{ name: 'In 2 days', key: 'IN_2_DAYS' },
		{ name: 'In 3 days', key: 'IN_3_DAYS' },
		{ name: 'In 5 days', key: 'IN_5_DAYS' },
	];

	const handleRenew = () => {
		if (checkedCount?.length > 0) {
			const payload = {
				resellerClientIds: checkedCount.map((item) => item.id),
				expiryDate: date,
			};
			dispatch(updateBulkClientExpiry(payload)).then((res) => {
				if (res?.type === 'pass/updateBulkClientExpiry/fulfilled') {
					dispatch(resellerClientByStatus());
					setUpdateRenewalModal(false);
				}
			});
		} else {
			// No client selected
			dispatch(
				updateResellerDate({
					resellerClientId: selectedClient?.id,
					expiryDate: date,
				})
			).then((res) => {
				if (res?.type === 'pass/updateResellerDate/fulfilled') {
					dispatch(resellerClientByStatus());
					setUpdateRenewalModal(false);
				}
			});
		}
	};

	return (
		<>
			<div className="clients">
				<Goback linkTo={'/dashboard'} />
				<div className="clients__header header--btn">
					<h4>Passes Expiring</h4>
					<div className="clients__header--filtersbox">
						<div
							className="clients__header--filterContainer"
							onMouseLeave={() => setOpenFilterList(false)}
						>
							<div
								className="clients__header--filter"
								onClick={() => setOpenFilterList(true)}
							>
								<Icon id="filterList" width="16" height="16" />
								<p>
									{selectedFilter?.name ? selectedFilter?.name : 'Filter by'}
								</p>
								<Icon id="down-caret" width="10" height="4" />
							</div>
							{openFilterList && (
								<div className="filterList">
									<ul>
										{filterOptions.map((item, index) => (
											<>
												<li
													onClick={() => {
														setSelectedFilter(item);
													}}
													key={index}
												>
													{item?.name}
												</li>
											</>
										))}
									</ul>
								</div>
							)}
						</div>

						<div
							className="clients__header--filterContainer"
							onMouseLeave={() => setOpenLabelsList(false)}
						>
							<div
								className="clients__header--filter"
								onClick={() => setOpenLabelsList(true)}
							>
								<Icon id="filterList" width="16" height="16" />

								<p>{selectedLabel ? selectedLabel : 'Label'}</p>

								<Icon id="down-caret" width="10" height="4" />
							</div>
							{openLabelsList && (
								<div className="filterList">
									<ul>
										{resellerLabels?.data?.length > 0 ? (
											<>
												<li
													onClick={() => {
														setSelectedLabel('');
													}}
												>
													All
												</li>
												{resellerLabels?.data?.map((item, index) => (
													<li
														onClick={() => {
															setSelectedLabel(item);
														}}
														key={index}
													>
														{item}
													</li>
												))}
											</>
										) : (
											<li className="filter-btn--dropdown--item">
												<span className="empty">
													You Currently Have no Labels
												</span>
											</li>
										)}
									</ul>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="clients__table mt-16">
					{isLoading ? (
						<Spinner />
					) : (
						<>
							{' '}
							{resellerClientsStatus?.length > 0 ? (
								<div className="clients__table--body mt-24">
									<ClientRenewalTable
										data={paginatedItems}
										handleCheck={handleCheck}
										setCheckAll={setCheckAll}
										checkAll={checkAll}
										handleCheckAll={handleCheckAll}
										setUpdateRenewalModal={setUpdateRenewalModal}
										setSelectedClient={setSelectedClient}
									/>
									<PaginationWithApi
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										totalPages={totalPages}
										handleNext={handleNext}
										handlePrev={handlePrev}
										paginationWithSlice={true}
									/>
								</div>
							) : (
								<div className="clients__table--body--no_records mt-24">
									<ClientRenewalTable
										data={paginatedItems}
										handleCheck={handleCheck}
										setCheckAll={setCheckAll}
										checkAll={checkAll}
										handleCheckAll={handleCheckAll}
										setUpdateRenewalModal={setUpdateRenewalModal}
										setSelectedClient={setSelectedClient}
									/>
									<div className="clients__table--body--no_records--container">
										<img src={NoRecords} />

										<div className="text">
											<span className="text--title">No records available</span>
											<span className="text--subTitle">
												No records found. Please adjust the filter and try
												again.
											</span>
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>

				{updateRenewalModal && (
					<UpdateResellersClientModal
						setUpdateRenewalModal={setUpdateRenewalModal}
						updateRenewalModal={updateRenewalModal}
						date={date}
						setDate={setDate}
						today={today}
						handleRenew={handleRenew}
						isLoading={loadSideModal}
					/>
				)}
			</div>
			{checkedCount?.length > 0 && (
				<div className="clients__footer">
					<div className="clients__footer--container">
						<div className="__left">
							<p className="selected">
								<span>{checkedCount?.length}</span>
								Selected
							</p>
							<p className="deselect" onClick={() => unCheckBoxes()}>
								Deselect
							</p>
						</div>
						<div className="__right">
							<p>
								Update Exp. Date for selected clients
								<span
									onClick={() => {
										setUpdateRenewalModal(true);
									}}
								>
									Yes, Update Exp. Date
								</span>
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ClientsRenew;
