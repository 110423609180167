import React, { useState, useRef } from 'react';
import Icon from '../Icon';


const MultiSelectInput = ({ 
  labels, 
  onChange, 
  placeholder = "Type and press Enter to add",
  className = "",
}) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const labelsArray = Array.isArray(labels) 
    ? labels 
    : typeof labels === 'string' 
      ? labels.split(',').map(label => label.trim()).filter(Boolean)
      : [];

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      addLabel(inputValue.trim());
    }
  };

  const addLabel = (label) => {
    const newLabels = [...labelsArray, label];
    onChange(newLabels.join(','));
    setInputValue('');
  };

  const removeLabel = (indexToRemove) => {
    const updatedLabels = labelsArray.filter((_, index) => index !== indexToRemove);
    onChange(updatedLabels.join(','));
  };

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };



  return (
    <div 
      className={`custom-multi-input ${className}`}
      onClick={handleContainerClick}
    >
      {labelsArray.map((label, index) => (
        <div 
          key={index} 
          className='custom-multi-input--label'
          onClick={(e) => e.stopPropagation()}
        >
          {label}
          <span onClick={(e) => {
            e.stopPropagation();
            removeLabel(index);
          }}>
            <Icon id='close-small' width='7' height='8' />
          </span>
        </div>
      ))}
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className="custom-multi-input--input"
      />
    </div>
  );
};

export default MultiSelectInput; 