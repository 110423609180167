import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
	// outline,
	variant,
	block,
	size,
	className,
	disabled,
	children,
	type,
	...rest
}) => {
	return (
		<button
			className={`btn 
                ${block ? 'btn--block' : ''}
                ${size ? 'btn--' + size : ''}
                ${variant ? 'btn--' + variant : ''}
                ${className ? className : ''}`}
			disabled={disabled}
			type={type}
			{...rest}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	block: PropTypes.bool,
	variant: PropTypes.oneOf([
		'primary',
		'secondary',
		'link',
		'danger',
		'success',
		'white',
		'blue',
	]),
	outline: PropTypes.bool,
	onClick: PropTypes.func,
	size: PropTypes.oneOf(['sm', 'md']),
	type: PropTypes.oneOf(['button', 'reset', 'submit']),
	className: PropTypes.string,
};

export default Button;
