import React from 'react';
import Table from './Table';
import { dateFormat, fmtCurrency } from '../../utils/helpers';
import Icon from '../Icon';

const TransactionTable = ({ data }) => {
	const [expandedIndex, setExpandedIndex] = React.useState(null);

	const toggleExpanded = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	const renderCell = (row, column) => {
		switch (column) {
			case 'Date':
				return dateFormat(row.dateCreated, 'MMM do, yyyy HH:mm') || '--';
			case 'Amount':
				return fmtCurrency(row.amount);
			case 'Name':
				return `${row.firstName || '-'} ${row.lastName || '-'}`;
			case 'Phone Number':
				return row.phoneNumber || '--';
			case 'Status':
				return (
					<span className={`status ${row.operationType.toLowerCase()}`}>
						{row.operationType}
					</span>
				);
			case 'Client Pass':
				return row.passCode || '--';
			case 'Client Id':
				return row.clientId || '--';
			case 'Txn Id':
				return row.transactionId || '--';
			case 'Txn Ref':
				return row.transactionRef || '--';
			default:
				return '--';
		}
	};

	const orderedColumns = [
		'Date',
		'Amount',
		'Name',
		'Phone Number',
		'Status',
		'',
		'Client Pass',
		'Client Id',
		'Txn Id',
		'Txn Ref',
	];

	return (
		<Table
			type="transaction"
			blueHeader={true}
			columns={orderedColumns.slice(0, 6)}
		>
			{data?.map((row, index) => (
				<React.Fragment key={index}>
					<tr>
						{orderedColumns.slice(0, 5).map((column, colIndex) => (
							<td
								key={colIndex}
								className={
									expandedIndex === index
										? 'transaction-table expanded'
										: 'transaction-table'
								}
							>
								{expandedIndex === index && (
									<span className="column-name">{column}:</span>
								)}
								<span className="data-value">{renderCell(row, column)}</span>
							</td>
						))}
						<td
							className={
								expandedIndex === index
									? 'transaction-table action-icon-cell expanded'
									: 'transaction-table action-icon-cell'
							}
							onClick={() => toggleExpanded(index)}
						>
							<span className="action-icon">
								<Icon
									id={expandedIndex === index ? 'up-caret-2' : 'down-caret-2'}
									width="16"
									height="16"
								/>
							</span>
						</td>
					</tr>
					{expandedIndex === index && (
						<tr>
							{orderedColumns.slice(6).map((column, colIndex) => (
								<td
									key={colIndex}
									className="transaction-table expanded bottom"
								>
									<span className="column-name">{column}:</span>
									<span className="data-value">{renderCell(row, column)}</span>
								</td>
							))}
							<td className="transaction-table expanded bottom"></td>
							<td className="transaction-table expanded bottom"></td>
						</tr>
					)}
				</React.Fragment>
			))}
		</Table>
	);
};

export default TransactionTable;
