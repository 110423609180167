import React from 'react';
import Icon from './Icon';
import { formatNumberWithCommas, formatTierName } from '../utils/helpers';

const PassCard = ({ pass }) => {
	return (
		<div className="dashboard__body--card">
			<div className="card--header">
				<span>
					<Icon width={32} height={32} id={'bluePassCircle'} />
				</span>
				<div className="header__content">
					<h2>{pass?.name}</h2>
					<p>{pass?.description}</p>
				</div>
			</div>
			<div className="card--body">
				<div className="card--deets">
					<div className="card--deet">
						<p>VISITS ALLOWED</p>
						<p className="subText">
							{pass?.maximumVisits} Visits / {pass?.duration}
						</p>
					</div>
					<div className="card--deet">
						<p>FITNESS CENTERS COVERED</p>
						<p className="subText">{pass?.centerAccessCount}</p>
					</div>
				</div>
				<div className="card--deets">
					<div className="card--deet">
						<p>CLIENTS ENROLLED ON THIS PASS</p>
						<p className="subText">
							{formatNumberWithCommas(pass?.enrolledClientsCount)}
						</p>
					</div>
				</div>
				<div className="card--deets ">
					<div className="card--deet">
						<p>Tiers accessible</p>
						<div className="tier">
							{pass?.supportedTiers?.map((tier, index) => (
								<div key={index} className="tier--info">
									<p>{formatTierName(tier?.tier)}</p>
									<p className="subText">
										₦{formatNumberWithCommas(tier?.price)}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PassCard;
