import React from "react";
import {Modal} from "../Modal";
import Button from "../Button";
import Icon from "../Icon";


const DeleteClientModal = ({
  setShowDeleteModal,
  showDeleteModal,
  onDelete,
  selectedClientId,
}) => {

  return (
    <Modal
      handleClose={() => {
        setShowDeleteModal(false);
      }}
      isHeader={false}
      show={showDeleteModal}
      //   containerClassName={`success-modal ${containerClassName}`}
      isModalSmall={true}
      containerClassName="deltePlans"
    >
      <span>
        <Icon id={"waarning"} height={"64"} width={"64"} />
      </span>
      <h4 className="mt-16">Delete Client</h4>
      <p className="mt-8 co-gray">Deleting your client will permanently remove it</p>
      <div className="btn--container--small">
        <Button
          variant="secondary"
          type="submit"
          className="mt-16"
          block
          size={"md"}
          onClick={() => setShowDeleteModal(false)}
        >
          no, Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="mt-16"
          block
          size={"md"}
          onClick={() => onDelete(selectedClientId)}
        >
          Yes, Delete Client
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteClientModal;
