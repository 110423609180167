import React, { useEffect, useState } from 'react';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStates } from '../../../features/user/userSlice';
import { useParams } from 'react-router-dom';
import {
	getAllResellerGyms,
	getAllResellerGymsToExport,
} from '../../../features/resellers/resellersSlice';
import GymTable from '../../../components/table/GymTable';
import PaginationWithApi from '../../../components/PaginationWithApi';
import Spinner from '../../../components/PageLoader';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import Goback from '../../../components/Goback';

const CoverageList = () => {
	const dispatch = useDispatch();
	const { tiers } = useParams();
	const tiersArray = tiers.split(',');
	const { allStates } = useSelector((store) => store.user);
	const { gyms, loadSideModal } = useSelector((store) => store.resellers);
	const [stateSelected, setStateSelected] = useState({});
	const [openFilterList, setOpenFilterList] = useState(false);
	const [openStateDropdown, setOpenStateDropdown] = useState(false);
	const [openLgaDropdown, setOpenLgaDropdown] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState('');
	const [lgaSelected, setLgaSelected] = useState({});
	const [currentPage, setCurrentPage] = useState(0);

	const handleNext = () => {
		if (!gyms?.last) {
			const nextPage = currentPage + 1;
			setCurrentPage(nextPage);
		}
	};

	const handlePrev = () => {
		if (currentPage > 0) {
			const prevPage = currentPage - 1;
			setCurrentPage(prevPage);
		}
	};

	useEffect(() => {
		if (selectedFilter || stateSelected?.id || lgaSelected?.id) {
			setCurrentPage(0);
		}
	}, [selectedFilter, stateSelected?.id, lgaSelected?.id]);

	useEffect(() => {
		dispatch(getAllStates());
		dispatch(
			getAllResellerGyms({
				state: stateSelected?.id,
				lgaIds: [lgaSelected?.id],
				tiers:
					selectedFilter === '' || selectedFilter === 'All'
						? tiersArray
						: [selectedFilter],
				page: currentPage,
			})
		);
	}, [currentPage, stateSelected?.id, lgaSelected?.id, selectedFilter]);

	const handleClearFilter = () => {
		setSelectedFilter('');
		setStateSelected({});
		setLgaSelected({});
	};

	const fetchDataToExport = () => {
		const payload = {
			state: stateSelected?.id,
			lgaIds: [lgaSelected?.id],
			tiers:
				selectedFilter === '' || selectedFilter === 'All'
					? tiersArray
					: [selectedFilter],
		};
		return dispatch(getAllResellerGymsToExport(payload));
	};
	const handleExportToExcel = async () => {
		try {
			if (!gyms?.content || gyms?.content?.length === 0) {
				toast.error('No gyms to export');
				return;
			}

			const response = await fetchDataToExport();

			const gymsToCsv = response?.payload?.content;

			// Format data for Excel
			const excelData = gymsToCsv?.map((row) => ({
				'FITNESS CENTER NAME': row?.name,
				STATE: row?.address?.state,
				CITY: row?.address?.city,
				ADDRESS: row?.address?.street,
				TIER: row?.tier,
			}));

			const worksheet = XLSX.utils.json_to_sheet(excelData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Gyns');

			// Generate filename with date
			const today = new Date().toISOString().slice(0, 10);
			const fileName = `FLance_GYMS_${selectedFilter.replace(
				/\s+/g,
				''
			)}_${today}.xlsx`;

			// Save the file
			XLSX.writeFile(workbook, fileName);
			toast.success('Gyms exported successfully');
		} catch (error) {
			console.error('Export error:', error);
			toast.error('Failed to export Gyms');
		}
	};
	return (
		<div className="coverage">
			<Goback linkTo={'/dashboard'} />
			<div className="coverage__header">
				<h4>COVERAGE LIST</h4>
				<div className="coverage__header--buttons">
					<div
						className="state__select"
						onMouseLeave={() => {
							setOpenStateDropdown(false);
							setOpenLgaDropdown(false);
						}}
					>
						<div className="state__filters">
							<div
								className="coverage__header--filter location"
								onClick={() => {
									setOpenStateDropdown(true);
									setOpenLgaDropdown(false);
								}}
							>
								<p>{stateSelected?.name ? stateSelected?.name : 'State'}</p>
								<Icon id="down-caret" width="10" height="4" />
							</div>
							<hr />
							<div
								className={`coverage__header--filter location ${
									!stateSelected?.name ? 'not-allowed' : ''
								}`}
								onClick={() => {
									if (stateSelected?.name) {
										setOpenLgaDropdown(true);
										setOpenStateDropdown(false);
									}
								}}
							>
								<p>{lgaSelected?.name ? lgaSelected?.name : 'LGA'}</p>
								<Icon id="down-caret" width="10" height="4" />
							</div>
						</div>
						{(openStateDropdown || openLgaDropdown) && (
							<div className="state__select--listContainer">
								<div className="list">
									{openStateDropdown ? (
										<div>
											{allStates.map((states) => (
												<label key={states?.id}>
													{states?.name}

													<input
														type="radio"
														name={states?.name}
														onChange={() => {
															setStateSelected(states);
															setLgaSelected({});
														}}
														checked={stateSelected?.name === states?.name}
													/>
												</label>
											))}
										</div>
									) : (
										<div>
											{stateSelected?.lgas?.map((lga) => (
												<label key={lga?.id}>
													{lga?.name}

													<input
														type="radio"
														name={lga?.name}
														onChange={() => setLgaSelected(lga)}
														checked={lgaSelected?.name === lga?.name}
													/>
												</label>
											))}
										</div>
									)}
								</div>
								<div className="btns">
									<Button
										variant={'reset'}
										size={'sm'}
										className={'filterBtn'}
										onClick={() => {
											setStateSelected({});
											setLgaSelected({});
										}}
									>
										Reset
									</Button>
								</div>
							</div>
						)}
					</div>

					<div
						className="coverage__header--filterContainer"
						onMouseLeave={() => setOpenFilterList(false)}
					>
						<div
							className="coverage__header--filter"
							onClick={() => setOpenFilterList(true)}
						>
							<Icon id="filterList" width="16" height="16" />
							<p>{selectedFilter ? selectedFilter : 'Filter by'}</p>
							<Icon id="down-caret" width="10" height="4" />
						</div>
						{openFilterList && (
							<div className="filterList">
								<ul>
									<>
										<li
											onClick={() => {
												setSelectedFilter('All');
											}}
										>
											All
										</li>
									</>
									{tiersArray?.map((item, index) => (
										<>
											<li
												onClick={() => {
													setSelectedFilter(item);
												}}
												key={index}
											>
												{item}
											</li>
										</>
									))}
								</ul>
							</div>
						)}
					</div>

					<div className="export-btn allow" onClick={handleExportToExcel}>
						<Icon id="download" width="16" height="16" className={'svg'} />
						<span>Export XLSX</span>
					</div>
				</div>
			</div>
			<div className="coverage__body mt-24">
				{loadSideModal ? (
					<Spinner />
				) : (
					<GymTable data={gyms.content} handleClearFilter={handleClearFilter} />
				)}

				{gyms?.content?.length > 0 && (
					<PaginationWithApi
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						totalPages={gyms?.totalPages}
						handleNext={handleNext}
						handlePrev={handlePrev}
					/>
				)}
			</div>
		</div>
	);
};

export default CoverageList;
