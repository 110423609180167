import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../components/Icon';
import toast from 'react-hot-toast';
import { getUserFromLocalStorage } from '../../../utils/localstorage';
import { resellerApisKey } from '../../../features/resellers/resellersSlice';

const ApiCred = () => {
	const [apiKey, setApiKey] = useState('');
	const [isCopied, setIsCopied] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const userDetails = getUserFromLocalStorage();
	const dispatch = useDispatch();
	const { apiKeyForResellers, apiKeyLoading } = useSelector(
		(store) => store.resellers
	);
	const notifySuccess = (message) => toast.success(message);
	useEffect(() => {
		if (userDetails?.resellerAPIKey) {
			setApiKey(userDetails?.resellerAPIKey);
		}
	}, [userDetails?.resellerAPIKey]);
	useEffect(() => {
		if (apiKeyForResellers?.data?.apikey) {
			userDetails.resellerAPIKey = apiKeyForResellers?.data?.apikey;
			localStorage.setItem('user', JSON.stringify(userDetails));
			setApiKey(apiKeyForResellers?.data?.apikey);
		}
	}, [apiKeyForResellers?.data?.apikey]);

	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(apiKey);
			setIsCopied(true);
			notifySuccess('copied');
			setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
		} catch (error) {
			console.error('Failed to copy text: ', error);
		}
	};
	return (
		<div className="settings__container">
			<>
				<div className="credentials">
					<h3>API Credentials</h3>
					<p>Get API keys to integrate Flance passes into your platform.</p>
					<div className="credentials--body">
						<h4>API Key</h4>
						<div className="input--field">
							<input
								value={apiKey}
								disabled
								className={`${isVisible ? 'visible' : ''}`}
							/>
							<span>
								<Icon
									id={isVisible ? 'eye-icon' : 'eye-close'}
									width={isVisible ? '16' : '24'}
									height={isVisible ? '15' : '24'}
									onClick={() => setIsVisible((visible) => !visible)}
								/>
							</span>
						</div>
						<div className="btn">
							<Button variant="primary" size="md" onClick={handleCopy}>
								{isCopied ? 'Copied!' : 'Copy'}
							</Button>
							<Button
								variant="secondary"
								size="md"
								block
								onClick={() => {
									dispatch(resellerApisKey());
								}}
								disabled={apiKeyLoading}
							>
								{apiKeyLoading ? 'Resting....' : 'Reset Secret key'}
							</Button>
						</div>
					</div>
				</div>
				<a
					href="https://documenter.getpostman.com/view/26212669/2sAXxTbqYa#9144e39f-ded0-466c-9b9c-bbbe7df209a6"
					target="_blank"
					rel="noopener noreferrer"
					className="docs-link mt-40"
				>
					API Documentation
					<span>
						<Icon width={10} height={14} id={'right-caret'} />
					</span>
				</a>
			</>
		</div>
	);
};

export default ApiCred;
