import React, { useEffect, useState } from 'react';
import Input from './forms/Input';
import Select from './forms/Select';
import Button from './Button';
import MultiSelectInput from './forms/MultiSelectInput';
import ClientCheckBox from './ClientCheckBox';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
	getResellablePass,
	submitClientsWithForm,
} from '../features/resellers/resellersSlice';

import PhoneInput from './forms/PhoneInput';

const AddClientsManually = () => {
	const navigate = useNavigate();
	const [phoneDetails, setPhoneDetails] = useState({});

	console.log(phoneDetails);
	const phoneRegExp =
		/((^090)([0-9]))|((^070)([0-9]))|((^080)([0-9]))|((^091)([0-9]))|((^071)([0-9]))|((^081)([0-9]))(\d{7})/;
	const [label, setLabel] = useState('');
	const schema = Yup.object({
		firstName: Yup.string().required('First name is required'),
		lastName: Yup.string().required('Last name is required'),
		phone: Yup.string()
			.matches(phoneRegExp, 'Phone number is not valid')
			.min(11, 'Phone number cannot be less than 11 characters')
			.max(14, 'Phone number cannot be longer than 14 characters'),
		email: Yup.string().when('sendPassCodeViaEmail', {
			is: true,
			then: (schema) =>
				schema
					.required('Client Email is required')
					.email('Email field is required'),
			otherwise: (schema) =>
				schema.notRequired().email('Email field is required'),
		}),
		// Yup.string()
		// .required('Email field is required')
		// .email('Invalid email address'),
		useClientIdAsPassCode: Yup.boolean().default(false),
		sendPassCodeViaEmail: Yup.boolean().default(false),
		clientId: Yup.string().when('useClientIdAsPassCode', {
			is: true,
			then: (schema) => schema.required('Client ID is required'),
			otherwise: (schema) => schema.notRequired(),
		}),
	});

	const { resellerPasses, submitingFrom } = useSelector(
		(store) => store.resellers
	);

	const dispatch = useDispatch();
	useEffect(() => {
		Promise.all([dispatch(getResellablePass())]);
	}, []);

	const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
		useFormik({
			initialValues: {
				firstName: '',
				lastName: '',
				email: '',

				clientId: '',
				useClientIdAsPassCode: false,
				sendPassCodeViaEmail: false,
				flancePassId: '',
				expiryDate: '',
			},
			validationSchema: schema,
			onSubmit: (values) => {
				const {
					firstName,
					lastName,
					email,

					expiryDate,
					clientId,
					useClientIdAsPassCode,
					sendPassCodeViaEmail,
					flancePassId,
				} = values;
				dispatch(
					submitClientsWithForm({
						firstName,
						lastName,
						email,
						label,
						phone: phoneDetails?.phoneNumber,
						expiryDate: expiryDate ? `${expiryDate}T00:00:00.000000` : '',
						clientId: clientId ? clientId : null,
						useClientIdAsPassCode,
						sendPassCodeViaEmail,
						flancePassId: flancePassId ? Number(flancePassId) : '',
						...(phoneDetails?.phoneNumber && {
							callingCode: phoneDetails?.country?.dialCode,
						}),
					})
				).then((res) => {
					if (res.type === 'pass/submitClientsWithForm/fulfilled') {
						navigate('/people');
					}
				});
			},
		});

	return (
		<form onSubmit={handleSubmit}>
			<div className="inputgroup">
				<Input
					type="text"
					id="firstName"
					label="First name"
					name="firstName"
					value={values.firstName}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="First name"
					error={
						touched.firstName && errors.firstName ? (
							<small className="error">{errors.firstName}</small>
						) : null
					}
				/>
				<Input
					type="text"
					id="lastName"
					label="Last name"
					name="lastName"
					value={values.lastName}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="Last name"
					error={
						touched.lastName && errors.lastName ? (
							<small className="error">{errors.lastName}</small>
						) : null
					}
				/>
			</div>
			<Input
				type="email"
				id="email"
				label="Email address"
				name="email"
				value={values.email}
				onChange={handleChange}
				onBlur={handleBlur}
				error={
					touched.email && errors.email ? (
						<small className="error">{errors.email}</small>
					) : null
				}
			/>
			<Input
				type="test"
				id="clientId"
				label="Client ID"
				name="clientId"
				value={values.clientId}
				onChange={handleChange}
				onBlur={handleBlur}
				error={
					touched.clientId && errors.clientId ? (
						<small className="error">{errors.clientId}</small>
					) : null
				}
				helperText={"This can be the customer's policy number"}
			/>
			<div className="phoneInput">
				<PhoneInput setPhoneDetails={setPhoneDetails} />
			</div>
			<Select
				options={resellerPasses?.map((item) => ({
					name: item.name,
					value: item.id,
				}))}
				onChange={handleChange}
				value={values.flancePassId}
				name="flancePassId"
				id="flancePassId"
				placeholder="Assign Pass"
				className={'mt-24'}
			/>

			<Input
				type="date"
				id="expiryDate"
				label="Set Pass Expiry Date"
				name="expiryDate"
				value={values.expiryDate}
				onChange={handleChange}
				onBlur={handleBlur}
				isFloatingLabel={false}
			/>
			<MultiSelectInput 
					labels={label}
					onChange={setLabel}
					placeholder="Type and press Enter to add Labels"
					className="mt-30"
			/>
			<span className="mini-text">Add labels (e.g., fitness, wellness). This helps with filtering and organization.</span>
			<ClientCheckBox
				handleChange={handleChange}
				useClientIdAsPassCode={values?.useClientIdAsPassCode}
				sendPassCodeViaEmail={values.sendPassCodeViaEmail}
			/>
			<Button
				variant="primary"
				type="submit"
				className={'mt-24'}
				block
				disabled={submitingFrom}
			>
				{submitingFrom ? 'Submiting...' : 'Submit'}
			</Button>
		</form>
	);
};

export default AddClientsManually;
