import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice';
import employeesSlice from './features/employees/employeesSlice';
// import PlanSlice from './features/Plan/PlanSlice';
import planSlice from './features/plan/planSlice';
import resellersSlice from './features/resellers/resellersSlice';
import countrySlice from './features/country/countrySlice';
import transactionSlice from './features/transactions/transactionSlice';

export const store = configureStore({
	reducer: {
		user: userSlice,
		employees: employeesSlice,
		plan: planSlice,
		transaction: transactionSlice,
		resellers: resellersSlice,
		country: countrySlice,
	},
});
