import React from 'react';

const Thead = ({
	columns,
	type,
	blueHeader = false,
	transparentHeader = false,
	checkAll,
	handleCheckAll,
}) => {
	return (
		<tr>
			{columns.map((column, index) => (
				<>
					{column === 'check' ? (
						<th
							key={index}
							className={`${blueHeader ? 'blueHeader' : ''} ${
								transparentHeader ? 'transparentHeader' : ''
							} ${type === 'transaction' ? 'transaction-th' : ''}`}
						>
							<input
								type="checkbox"
								className="check"
								checked={checkAll}
								onChange={handleCheckAll}
							/>
						</th>
					) : (
						<th
							key={index}
							className={`${blueHeader ? 'blueHeader' : ''} ${
								transparentHeader ? 'transparentHeader' : ''
							} ${type === 'transaction' ? 'transaction-th' : ''}`}
						>
							{column}
						</th>
					)}
				</>
			))}
		</tr>
	);
};

export default Thead;
