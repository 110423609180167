import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/forms/Input';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDetails } from '../../../features/user/userSlice';
import {
	changeNotificationStatus,
	clientIdAccessForPassCode,
} from '../../../features/resellers/resellersSlice';
import { getUserFromLocalStorage } from '../../../utils/localstorage';

const Accounts = () => {
	const { businessDetails, updateLoading } = useSelector((store) => store.user);
	const { loadSideModal, isLoading } = useSelector((store) => store.resellers);
	const userDetails = getUserFromLocalStorage();
	const dispatch = useDispatch();
	const schema = Yup.object({
		name: Yup.string(),
		email: Yup.string().email(),
	});

	const { handleSubmit, handleChange, handleBlur, values } = useFormik({
		initialValues: {
			email: businessDetails?.businessEmail,
			name: businessDetails?.businessName,
		},
		validationSchema: schema,
		onSubmit: (values) => {
			dispatch(
				updateUserDetails({
					businessName: '',
					businessEmail: values?.email,
					firstName: '',
					lastName: '',
					contactEmail: '',
					phoneNumber: '',
					callBackUrl: `${window.location.origin}/auth/verifying-mail`,
				})
			);
		},
	});

	const [sendNotification, setSendNotification] = useState(false);
	const [clientIdAccess, setClientIdAccess] = useState(false);

	useEffect(() => {
		if (userDetails?.useClientIdAsPassCode) {
			setClientIdAccess(userDetails?.useClientIdAsPassCode);
		}
		if (userDetails?.smsEnabled && userDetails?.emailEnabled) {
			setSendNotification(true);
		}
	}, [userDetails]);

	return (
		<div className="settings__container">
			<div className="settings__container--header">
				<h2>Business Logo</h2>
				<div className="img__container">
					{businessDetails?.businessName.substring(0, 2)}
				</div>
			</div>
			<div className="settings__container--body">
				<h2>Business Info</h2>
				<p>Basic information about your business.</p>
				<form className="settings_form" onSubmit={handleSubmit}>
					<Input
						type="name"
						id="name"
						value={values.name}
						onChange={handleChange}
						onBlur={handleBlur}
						label="Business Name"
						name="name"
						disabled={true}
					/>
					<Input
						type="email"
						id="email"
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
						label="Business email"
						name="email"
					/>

					<p>
						If this email is edited, verification will be needed for the change
						to be effective.
					</p>

					<Button
						variant="primary"
						type="submit"
						className="mt-32"
						block
						disabled={updateLoading}
					>
						{updateLoading ? 'Loading...' : 'Update'}
					</Button>
				</form>
			</div>
			<div className="settings__container--notifications">
				<h2>Notification Settings</h2>
				<p>Allow Flance send passcode</p>
				<div className="notifications">
					<div className="toggle--container">
						{loadSideModal && <div className="mini-loader"></div>}
						<div className="toggle-switch">
							<input
								type="checkbox"
								className="checkbox"
								name={`sendNotification`}
								id={`sendNotification`}
								checked={sendNotification}
								onChange={() => {
									dispatch(
										changeNotificationStatus({
											smsEnabled: !sendNotification,
											emailEnabled: !sendNotification,
										})
									).then((res) => {
										if (
											res?.type === 'pass/changeNotificationStatus/fulfilled'
										) {
											setSendNotification(!sendNotification);
										}
									});
								}}
							/>
							<label className="label" htmlFor={`sendNotification`}>
								<span className="inner" />
								<span className="switch" />
							</label>
						</div>
					</div>
					<p>Allow Flance send passcode via Email and SMS to your clients </p>
				</div>
			</div>
			<div className="settings__container--notifications">
				<h2>Client ID Access</h2>
				<p>Allow Flance send passcode</p>
				<div className="notifications">
					<div className="toggle--container">
						{isLoading && <div className="mini-loader"></div>}
						<div className="toggle-switch">
							<input
								type="checkbox"
								className="checkbox"
								name={`clientIdAccess`}
								id={`clientIdAccess`}
								checked={clientIdAccess}
								onChange={() => {
									dispatch(clientIdAccessForPassCode()).then((res) => {
										if (
											res?.type === 'pass/clientIdAccessForPassCode/fulfilled'
										) {
											setClientIdAccess(!clientIdAccess);
										}
									});
								}}
							/>
							<label className="label" htmlFor={`clientIdAccess`}>
								<span className="inner" />
								<span className="switch" />
							</label>
						</div>
					</div>
					<p>You can use your ClientID as their passcode </p>
				</div>
			</div>
		</div>
	);
};

export default Accounts;
