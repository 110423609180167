export const navigations = [
	{
		id: 0,
		link: '/dashboard',
		icon: 'plans',
		coloredIcon: 'blue-plans',
		name: 'Dashboard',
		navName: 'dashboard',
	},
	{
		id: 1,
		link: '/people',
		icon: 'person',
		coloredIcon: 'blue-person',
		name: 'People',
		navName: 'people',
	},
	{
		id: 2,
		link: '/transactions',
		icon: 'credit',
		coloredIcon: 'blue-credit',
		name: 'Transactions',
		navName: 'transactions',
	},
	{
		id: 3,
		link: '/settings',
		icon: 'settings',
		coloredIcon: 'blue-settings',
		name: 'Settings',
		navName: 'settings',
	},
];

export const settingsNavigation = [
	{
		id: 0,
		link: '/settings',
		name: 'Account',
		pathName: undefined,
	},
	{
		id: 1,
		link: '/settings/contact-info',
		name: 'Contact Info',
		pathName: 'contact-info',
	},
	{
		id: 2,
		link: '/settings/card',
		name: 'Card',
		pathName: 'card',
	},
	{
		id: 3,
		link: '/settings/documents',
		name: 'Documents',
		pathName: 'documents',
	},
	{
		id: 4,
		link: '/settings/security',
		name: 'Security',
		pathName: 'security',
	},
	{
		id: 4,
		link: '/settings/api',
		name: 'API Credentials',
		pathName: 'api',
	},
];
