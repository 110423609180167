import PagesLogo from '../assets/svgs/PagesIcon.svg';
import TechPr from '../assets/images/TechPr-logo.png';
import Zedi from '../assets/svgs/Zedi.svg';

import { format } from 'date-fns';

export const carouselDetails = [
	{
		id: 0,
		name: 'Onyinye Bassey',
		position: 'Head of Strategic Sourcing and Procurement',
		testimonial: `"Flance helps you save money, and live a fuller life
        whether healthcare, pension, or savings."`,
		companyLogo: PagesLogo,
		altText: 'Pages by dami',
	},
	{
		id: 1,
		name: 'Alex Johnson',
		position: 'Head of People',
		testimonial: `"I really like your service and would refer my friends. You guys seem like you really care, I would also like to know when I can add my family to a plan."`,
		companyLogo: TechPr,
		altText: 'TechPr logo',
	},
	{
		id: 2,
		name: 'Gerry Franklin',
		position: 'Founder and CEO',
		testimonial: `"I really like your service and I like how your follow-ups. I will definitely refer Flance to my circle. Keep up the good work."`,
		companyLogo: Zedi,
		altText: 'Zedi logo',
	},
];

export const planFilters = [
	{
		id: 0,
		filterType: 'all',
		filterName: 'All',
	},
	{
		id: 2,
		filterType: 'Health Insurance',
		filterName: 'Health Insurance',
	},
	{
		id: 3,
		filterType: 'Savings',
		filterName: 'Savings',
	},
	{
		id: 3,
		filterType: 'Gym',
		filterName: 'Gym',
	},
	{
		id: 3,
		filterType: 'Corporate Health Insurance',
		filterName: 'Corporate Health Insurance',
	},
];

export const paginate = (employees, itemsPerPage = 7) => {
	const numberOfPages = Math.ceil(employees.length / itemsPerPage);

	const newFollowers = Array.from({ length: numberOfPages }, (_, index) => {
		const start = index * itemsPerPage;
		return employees.slice(start, start + itemsPerPage);
	});

	return newFollowers;
};

export function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		if (pair[0] === variable) {
			return pair[1];
		}
	}
	return false;
}

export function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const fmtCurrency = (value, currency) => {
	const formatter = new Intl.NumberFormat('en-NG', {
		style: 'currency',
		currencyDisplay: 'symbol',
		currency: currency ? currency : 'NGN',
	});
	return formatter.format(Number(value));
};

export const dateFormat = (date, type) => {
	if (!date) return '--';
	return format(new Date(date), type);
};

export const getDaysLeft = (date) => {
	let currentDate = new Date();

	// Target date
	let targetDate = new Date(date); // Replace with your desired target date

	// Calculate the time difference in milliseconds
	let timeDifference = targetDate - currentDate;

	// Convert milliseconds to days
	let daysLeft = Math.ceil(timeDifference / (24 * 60 * 60 * 1000));

	return daysLeft;
};

export function convertBytesToKBAndMB(bytes) {
	const kilobytes = bytes / 1024;

	if (kilobytes < 1000) {
		return kilobytes.toFixed(2) + ' KB';
	} else {
		const megabytes = kilobytes / 1024;
		return megabytes.toFixed(2) + ' MB';
	}
}

export const limitString = (str, limit) => {
	return str?.length > limit ? str?.substring(0, limit) : str;
};

export function formatNumberWithCommas(number = 0) {
	return number.toLocaleString();
}

export function formatTierName(str = '') {
	return str.replace(/teir/i, 'tier').replace('_', ' ');
}

export function getTimeUnit(frequency) {
	switch (frequency?.toUpperCase()) {
		case 'ANNUALLY':
			return 'year';
		case 'YEARLY':
			return 'year';
		case 'BIANNUALLY':
			return '6 months';
		case 'QUARTERLY':
			return '3 months';
		case 'MONTHLY':
			return 'month';
		case 'BI_WEEKLY':
			return '2 weeks';
		case 'WEEKLY':
			return 'week';
		case 'DAILY':
			return 'day';
		default:
			return '--';
	}
}

export const formatDate = (date) => {
	if (!date) return "";
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
  };
  
  export const getDateRange = (filterValue, customStartDate, customEndDate) => {
	const today = new Date();
	let startDate, endDate;
	console.log(filterValue, 'filterValue');
	console.log(customStartDate, 'customStartDate');
	console.log(customEndDate, 'customEndDate');
  
	switch (filterValue) {
	  case "Today":
		startDate = endDate = formatDate(today);
		break;
	  case "Past 3 Days":
		startDate = formatDate(new Date(today.setDate(today.getDate() - 3)));
		endDate = formatDate(new Date());
		break;
	  case "This week": {
		const monday = new Date(
		  today.setDate(today.getDate() - today.getDay() + 1)
		);
		startDate = formatDate(monday);
		endDate = formatDate(new Date(monday.setDate(monday.getDate() + 6)));
		break;
	  }
	  case "This month":
		startDate = formatDate(
		  new Date(today.getFullYear(), today.getMonth(), 1)
		);
		endDate = formatDate(
		  new Date(today.getFullYear(), today.getMonth() + 1, 0)
		);
		break;
	  case "This year":
		startDate = `${today.getFullYear()}-01-01`;
		endDate = `${today.getFullYear()}-12-31`;
		break;
	  case "Custom":
		startDate = customStartDate;
		endDate = customEndDate;
		break;
	  default:
		startDate = endDate = undefined;
	}
	return { startDate, endDate };
  };
