import React from 'react';
import Table from './Table';
import skeletonLoading from '../../assets/svgs/chat-thread.svg';
import Button from '../Button';
import Icon from '../Icon';

const ClientsTable = ({
	setOpenSideModal,
	data,
	setSelectedClient,
	handleClearFilter,
}) => {
	const [openDropdownId, setOpenDropdownId] = React.useState(null);
	
	const columns = [
		'First Name',
		'Last Name',
		'Client ID',
		'Label',
		'Pass Type',
	];

	return (
		<>
			{data?.length < 1 ? (
				<div className="emptyClients">
					<div className="noEmployee__container">
						<img src={skeletonLoading} alt="loading" />
						<h4>Client not Found</h4>
						<p>
							No matching client was found. Please verify the information and
							search again.
						</p>
						<Button
							variant={'primary'}
							size={'md'}
							onClick={handleClearFilter}
							block={true}
							className={'mt-24'}
						>
							Show all clients
						</Button>
					</div>
				</div>
			) : (
				<Table columns={columns} blueHeader={true}>
					<>
						{data?.map((row) => (
							<tr
								className="pointer grey-bg"
								onClick={() => {
									setOpenSideModal(true);
									setSelectedClient(row);
								}}
								key={row?.id}
							>
								<td className="plan-table ">{row?.firstName}</td>
								<td className="plan-table duration">{row?.lastName}</td>
								<td className="plan-table">{row?.clientId}</td>
								<td className="plan-table">
									<div className="label-container">
										{row?.label ? (
											(() => {
												const labels = row.label
												return labels.length > 0 ? (
													<>
														<span className="label-field">{labels[0]}</span>
														{labels.length > 1 && (
															<>
																<span 
																	className="action-icon"
																	onClick={(e) => {
																		e.stopPropagation();
																		setOpenDropdownId(openDropdownId === row.id ? null : row.id);
																	}}
																>
																	<Icon
																		width="16"
																		height="16"
																		id="down-caret-2"
																	/>
																</span>
																{openDropdownId === row.id && (
																	<div className="label-dropdown">
																		{labels.slice(1).map((label, index) => (
																			<div key={index} className="label-item">
																				{label}
																			</div>
																		))}
																	</div>
																)}
															</>
														)}
													</>
												) : (
													<span className="label-field">N/A</span>
												)
											})()
										) : (
											<span className="label-field">N/A</span>
										)}
									</div>
								</td>
								<td className="plan-table">{row?.passName}</td>
							</tr>
						))}
					</>
				</Table>
			)}
		</>
	);
};

export default ClientsTable;
