import React from 'react';
import Logo from '../assets/svgs/logo.svg';
import Icon from './Icon';
import { navigations } from '../utils/navigations';
import { Link } from 'react-router-dom';

import HeadwayWidget from './HeadwayWidget';

const DashboardSIdebar = ({ splitLocation }) => {
	return (
		<div className="sidebar--container">
			<div className={` ${'layout__sidenav'}`} role="navigation">
				<div>
					<div className="layout__sidenav--banner">
						<img src={Logo} alt="flance logo" />
					</div>

					<HeadwayWidget />

					<ul>
						{navigations.map((navigation) => {
							return (
								<Link
									to={navigation.link}
									// onClick={() => setActive(navigation.link)}
									className={`navlinks ${
										splitLocation[1] === navigation.navName ? 'active' : ''
									}`}
									key={navigation.id}
								>
									<Icon
										width="24px"
										height="24px"
										id={`${
											splitLocation[1] === navigation?.navName
												? navigation?.coloredIcon
												: navigation?.icon
										}`}
									/>
									<p>{navigation?.name}</p>
								</Link>
							);
						})}
					</ul>
				</div>

				<div>
					<hr className="bottom-rule" />
					<a
						href="https://documenter.getpostman.com/view/26212669/2sAXxTbqYa#9144e39f-ded0-466c-9b9c-bbbe7df209a6"
						target="_blank"
						rel="noopener noreferrer"
						className="docs-link"
					>
						API Documentation
					</a>
				</div>
			</div>
		</div>
	);
};

export default DashboardSIdebar;
