import React, { useState } from 'react';
import Goback from '../../../components/Goback';
import AddClientsManually from '../../../components/AddClientsManually';
import ImportClients from '../../../components/ImportClients';

const ClinetsInfo = () => {
	const [isImportingClients, setIsImportingClients] = useState(false);

	return (
		<div className="clients">
			<Goback linkTo={'/people'} />
			<div className="clients__header">
				<>
					<h4>Client Information</h4>
					<p className="mini-text">
						Add clients information, (Include: first name, last name, phone
						number(optional), email and Client ID)
					</p>
					<div className="clients__header--pills">
						<div
							className={`clients__header--pill ${
								!isImportingClients ? 'active' : ''
							}`}
							onClick={() => setIsImportingClients(false)}
						>
							<p>Add New Clients </p>
						</div>
						<div
							className={`clients__header--pill ${
								isImportingClients ? 'active' : ''
							}`}
							onClick={() => {
								setIsImportingClients(true);
								localStorage.removeItem('clientUploadData');
							}}
						>
							<p>IMPORT CLIENTS</p>
						</div>
					</div>
					<div className="clients__body">
						{!isImportingClients ? <AddClientsManually /> : <ImportClients />}
					</div>
				</>
				{/* )} */}
			</div>
		</div>
	);
};

export default ClinetsInfo;
