import React, { useEffect, useState } from 'react';
import Icon from '../../../components/Icon';
import {
	dateFormat,
	fmtCurrency,
	formatDate,
	getDateRange,
} from '../../../utils/helpers';
import TransactionTable from '../../../components/table/TransactionTable';
import skeletonLoading from '../../../assets/svgs/noRecords.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
	getWalletTransactionDetails,
	getWalletTransactionDetailsForExcelExport,
	getWalletDetails,
} from '../../../features/transactions/transactionSlice';
import PaginationWithApi from '../../../components/PaginationWithApi';
import MobileTable from '../../../components/modals/MobileTable';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import DatePicker from 'react-date-picker';
import Spinner from '../../../components/PageLoader';
// import ActiveBenefits from '../../../components/ActiveBenefits';/

const Transactions = () => {
	const [isVisible, setIsVisible] = useState(true);
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentPageForPagination, setCurrentPageForPagination] = useState(1);
	const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
	const [selectedFilter, setSelectedFilter] = useState('All');
	const [customStartDate, setCustomStartDate] = useState(null);
	const [customEndDate, setCustomEndDate] = useState(null);

	const handlePrev = () => {
		if (currentPageForPagination === 1) return;
		setCurrentPage((p) => p - 1);
		setCurrentPageForPagination((p) => p - 1);
	};
	const handleNext = () => {
		if (currentPageForPagination === transactionDetails?.data?.totalPages)
			return;
		setCurrentPage((p) => p + 1);
		setCurrentPageForPagination((p) => p + 1);
	};
	const handleSetCurrentPage = (page) => {
		setCurrentPage(page - 1);
		setCurrentPageForPagination(page);
	};

	const dispatch = useDispatch();
	const { isLoading, walletDetails, transactionDetails } = useSelector(
		(store) => store.transaction
	);

	const filterOptions = ['All', 'Today', 'This week', 'This month', 'Custom'];

	useEffect(() => {
		setCurrentPage(0);
		setCurrentPageForPagination(1);
		dispatch(getWalletDetails());
		fetchTransactions();
	}, [selectedFilter, customEndDate, searchPhoneNumber]);

	useEffect(() => {
		dispatch(getWalletDetails());
		fetchTransactions();
	}, []);
	useEffect(() => {
		dispatch(getWalletDetails());
		fetchTransactions();
	}, [currentPage]);

	const fetchTransactions = () => {
		const { startDate, endDate } = getDateRange(
			selectedFilter,
			customStartDate,
			customEndDate
		);
		if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
			toast.error('End date cannot be earlier than start date');
			return;
		}
		if (selectedFilter === 'All') {
			setCustomEndDate(null);
			setCustomStartDate(null);
		}
		if (selectedFilter === 'Custom' && !startDate && !endDate) {
			return;
		}
		dispatch(
			getWalletTransactionDetails({
				startDate: startDate ?? '',
				endDate: endDate ?? '',
				phoneNumber: searchPhoneNumber,
				page: currentPage,
			})
		);
	};

	const fetchTransactionsForExcelExport = () => {
		const { startDate, endDate } = getDateRange(
			selectedFilter,
			customStartDate,
			customEndDate
		);
		return dispatch(
			getWalletTransactionDetailsForExcelExport({
				startDate: startDate ?? '',
				endDate: endDate ?? '',
				phoneNumber: searchPhoneNumber,
			})
		);
	};

	const handleExportToExcel = async () => {
		try {
			if (selectedFilter === 'All') {
				toast.error('Please select a filter to export');
				return;
			}

			const response = await fetchTransactionsForExcelExport();
			const transactions = response?.payload?.data?.content;

			if (!transactions || transactions.length === 0) {
				toast.error('No transactions to export');
				return;
			}

			// Format data for Excel
			const excelData = transactions.map((row) => ({
				Name: `${row.firstName} ${row.lastName}`,
				'Phone Number': row.phoneNumber,
				Date: dateFormat(row.dateCreated, 'MMM do, yyyy HH:mm'),
				Amount: fmtCurrency(row.amount),
				Status: row.operationType,
				'Client Pass': row.passCode,
				'Client ID': row.clientId,
				'Transaction ID': row.transactionId,
				'Transaction Reference': row.transactionRef,
			}));

			const worksheet = XLSX.utils.json_to_sheet(excelData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

			// Generate filename with date
			const today = new Date().toISOString().slice(0, 10);
			let fileName;
			if (selectedFilter === 'Custom') {
				const formattedStartDate = customStartDate.split('T')[0];
				const formattedEndDate = customEndDate.split('T')[0];
				fileName = `FLance_Transactions_${formattedStartDate}_to_${formattedEndDate}.xlsx`;
			} else {
				fileName = `FLance_Transactions_${selectedFilter.replace(
					/\s+/g,
					''
				)}_${today}.xlsx`;
			}

			// Save the file
			XLSX.writeFile(workbook, fileName);
			toast.success('Transactions exported successfully');
		} catch (error) {
			console.error('Export error:', error);
			toast.error('Failed to export transactions');
		}
	};

	return (
		<>
			<div className="transaction">
				<div className="credit__container">
					<div className="credit__container--card">
						<Icon id="transaction" width="60" height="60" className={'svg'} />
						<div className="credit__container--card--content">
							<div className="credit__container--card--content--header">
								<h4>Credit balance</h4>
								<Icon
									id={isVisible ? 'eye-icon-2' : 'eye-icon-closed'}
									width="18"
									height="16"
									className={'svg'}
									onClick={() => setIsVisible((visible) => !visible)}
								/>
							</div>
							<div className="credit__container--card--content--footer">
								{isVisible ? fmtCurrency(walletDetails?.balance) : '******'}
							</div>
						</div>
					</div>
				</div>
				<div className="transaction__container">
					<div className="transaction__header">
						<button
							className={
								selectedFilter !== 'All' ? 'export-btn outline' : 'export-btn'
							}
							onClick={handleExportToExcel}
						>
							<Icon id="download" width="16" height="16" className={'svg'} />
							<span>Export XLSX</span>
						</button>
						<div className="search-filter-container">
							<div className="search">
								<Icon width="16" height="16" id="u-search" />
								<input
									type="text"
									placeholder="Search phone number"
									value={searchPhoneNumber}
									onChange={(e) => {
										const value = e.target.value;
										if (/^\d*$/.test(value) && value.length <= 11) {
											setSearchPhoneNumber(value);
										}
									}}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											fetchTransactions();
										} else if (
											e.key === 'Backspace' &&
											searchPhoneNumber == ''
										) {
											fetchTransactions();
										}
									}}
								/>
							</div>

							<div
								onClick={() => setIsFilterOpen(!isFilterOpen)}
								onMouseLeave={() => setIsFilterOpen(false)}
								className="filter-btn"
							>
								<Icon width="16" height="16" id="date" />
								{selectedFilter === 'Custom' ? (
									<div
										className="custom__date--container"
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										<div className="date-picker-inputs">
											<span>FROM</span>
											<DatePicker
												prev2Label={null}
												next2Label={null}
												prevLabel={
													<Icon id="calendar-prev" width="12" height="12" />
												}
												nextLabel={
													<Icon id="calendar-next" width="12" height="12" />
												}
												value={
													customStartDate ? new Date(customStartDate) : null
												}
												onChange={(date) =>
													setCustomStartDate(formatDate(date))
												}
											/>
										</div>

										<Icon id="dash-small" width="6" height="2" />
										<div className="date-picker-inputs">
											<span>TO</span>
											<DatePicker
												prev2Label={null}
												next2Label={null}
												prevLabel={
													<Icon id="calendar-prev" width="12" height="12" />
												}
												nextLabel={
													<Icon id="calendar-next" width="12" height="12" />
												}
												value={customEndDate ? new Date(customEndDate) : null}
												onChange={(date) => setCustomEndDate(formatDate(date))}
											/>
										</div>
									</div>
								) : (
									<span>{selectedFilter}</span>
								)}
								<Icon width="16" height="16" id="down-caret-2" />
								{isFilterOpen && (
									<ul className="filter-btn--dropdown">
										{filterOptions.map((option) => (
											<li
												key={option}
												className="filter-btn--dropdown--item"
												onClick={() => setSelectedFilter(option)}
											>
												<span>{option}</span>
											</li>
										))}
									</ul>
								)}
							</div>
						</div>
					</div>
					{isLoading ? (
						<Spinner />
					) : (
						<div className="transaction__table">
							{transactionDetails?.data?.content?.length > 0 ? (
								<div className="table-container">
									<TransactionTable data={transactionDetails.data.content} />
									<div className="smaller__table">
										{transactionDetails?.data?.content?.map((row, index) => (
											<MobileTable
												key={index}
												email={`${dateFormat(
													row?.dateCreated,
													'MMM do, yyyy HH:mm' || '--'
												)}`}
												firstname={`${row?.firstName} ${row?.lastName}`}
												renewalDate={fmtCurrency(row?.amount)}
												threeDots={false}
												nameCircle={false}
												planName={' '}
											/>
										))}
									</div>

									<PaginationWithApi
										currentPage={currentPageForPagination}
										setCurrentPage={handleSetCurrentPage}
										totalPages={transactionDetails?.data?.totalPages}
										handleNext={handleNext}
										handlePrev={handlePrev}
										paginationWithSlice={true}
									/>
								</div>
							) : (
								<div className="noTransactions__container">
									<img src={skeletonLoading} alt="loading" />
									<span>
										{searchPhoneNumber
											? 'No phone number found'
											: 'No records found'}
									</span>
									<p>
										{searchPhoneNumber
											? 'No phone number found. Refine your search or try a different number.'
											: 'No records found for this date. Try adjusting the date range.'}
									</p>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Transactions;
