import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
// import SearchAddContainer from '../../../components/SearchAddContainer';
import ClientsTable from '../../../components/table/ClientsTable';
import ClientsDetailSideModal from '../../../components/modals/ClientsDetailSideModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAllRessellersClients,
	getResellablePass,
	getAllResellerLabels,
	csvUploadStatus,
} from '../../../features/resellers/resellersSlice';
import Spinner from '../../../components/PageLoader';
import PaginationWithApi from '../../../components/PaginationWithApi';
import Icon from '../../../components/Icon';
import { formatNumberWithCommas } from '../../../utils/helpers';

const People = () => {
	const navigate = useNavigate();
	const [userSearch, setUserSearch] = useState('');
	const [searchInput, setSearchInput] = useState('');
	const [openSideModal, setOpenSideModal] = useState(false);
	const [selectedClient, setSelectedClient] = useState({});
	const [currentPage, setCurrentPage] = useState(0); // Current page
	const {
		resellersClients,
		submitingFrom,
		resellerPasses,
		resellerLabels,
		csvUploadDetails,
	} = useSelector((store) => store.resellers);
	const [showBanner, setShowBanner] = useState(false);
	const [isPassesFilterOpen, setIsPassesFilterOpen] = useState(false);
	const [isLabelsFilterOpen, setIsLabelsFilterOpen] = useState(false);
	const [selectedPassFilter, setSelectedPassFilter] = useState({});
	const [selectedLabelFilter, setSelectedLabelFilter] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getResellablePass());
		dispatch(getAllResellerLabels());
		dispatch(csvUploadStatus()).then((res) => {
			if (res?.type === 'pass/clientIdAccessForPassCode/fulfilled') {
				setShowBanner(true);
			}
		});
	}, []);

	const handleNext = () => {
		if (!resellersClients?.last) {
			const nextPage = currentPage + 1;
			setCurrentPage(nextPage);
		}
	};

	const handlePrev = () => {
		if (currentPage > 0) {
			const prevPage = currentPage - 1;
			setCurrentPage(prevPage);
		}
	};

	const handleClearFilter = () => {
		setSearchInput('');
		setUserSearch('');
		setSelectedPassFilter({});
		setSelectedLabelFilter();
		setCurrentPage(0);
	};

	useEffect(() => {
		if (selectedPassFilter?.id || selectedLabelFilter || searchInput) {
			setCurrentPage(0);
		}
	}, [selectedPassFilter?.id, selectedLabelFilter, searchInput]);

	useEffect(() => {
		dispatch(
			getAllRessellersClients({
				page: currentPage,
				numPerPage: 25,
				passId: selectedPassFilter?.id,
				label: selectedLabelFilter,
				search: searchInput,
			})
		);
	}, [currentPage, selectedPassFilter?.id, selectedLabelFilter, searchInput]);

	return (
		<div className="People">
			{showBanner && (
				<div className="People__hint">
					<p>
						<span>
							<Icon id={'hintbulb'} height={'20'} width={'16'} />
						</span>
						{csvUploadDetails?.message}
					</p>
					<h4 onClick={() => setShowBanner(false)}>noted</h4>
				</div>
			)}

			<div className="People__header header--btn">
				<div className="People__header--title">
					<p>
						{' '}
						<span>
							{formatNumberWithCommas(resellersClients?.totalElements)}
						</span>{' '}
						People
					</p>
				</div>
				<div className="enterprise__header--btn">
					<Button
						variant={'primary'}
						size={'md'}
						onClick={() => {
							navigate('/add-clients');
						}}
					>
						<span className="add">+</span>
						Add New People
					</Button>
				</div>
			</div>
			<div className="People__table mt-16">
				<div className="People__table--header">
					<div className="search-filter-container People__table--header">
						<div className="filter--container">
							<div
								className="clients__header--filterContainer "
								onMouseLeave={() => setIsPassesFilterOpen(false)}
							>
								<div
									className="clients__header--filter"
									onClick={() => setIsPassesFilterOpen(true)}
								>
									<Icon id="filterList" width="16" height="16" />
									<p>
										{selectedPassFilter?.name
											? selectedPassFilter?.name
											: 'Passes'}
									</p>
									<Icon id="down-caret" width="10" height="4" />
								</div>
								{isPassesFilterOpen && (
									<div className="filterList postitonLeft">
										<ul>
											{resellerPasses?.map((item, index) => (
												<>
													<li
														onClick={() => {
															setSelectedPassFilter(item);
															setIsPassesFilterOpen(false);
														}}
														key={index}
													>
														{item?.name}
													</li>
												</>
											))}
										</ul>
									</div>
								)}
							</div>

							<div
								className="clients__header--filterContainer"
								onMouseLeave={() => setIsLabelsFilterOpen(false)}
							>
								<div
									className="clients__header--filter"
									onClick={() => setIsLabelsFilterOpen(true)}
								>
									<Icon id="filterList" width="16" height="16" />

									<p>{selectedLabelFilter ? selectedLabelFilter : 'Label'}</p>

									<Icon id="down-caret" width="10" height="4" />
								</div>
								{isLabelsFilterOpen && (
									<div className="filterList">
										<ul>
											{resellerLabels?.data?.length > 0 ? (
												<>
													<li
														onClick={() => {
															setSelectedLabelFilter('');
															setIsLabelsFilterOpen(false);
														}}
													>
														ALL
													</li>
													{resellerLabels?.data?.map((item, index) => (
														<li
															onClick={() => {
																setSelectedLabelFilter(item);
																setIsLabelsFilterOpen(false);
															}}
															key={index}
														>
															{item}
														</li>
													))}
												</>
											) : (
												<li className="filter-btn--dropdown--item">
													<span className="empty">
														You Currently Have no Labels
													</span>
												</li>
											)}
										</ul>
									</div>
								)}
							</div>
						</div>
						<div className="search--container">
							<div className="search">
								<Icon width="16" height="16" id="u-search" />
								<input
									type="text"
									placeholder="Search by name or client ID"
									value={userSearch}
									onChange={(e) => setUserSearch(e.target.value)}
								/>
							</div>
							<div className="search--container__btn">
								<Button
									variant="blue"
									size="sm"
									className="btn--block "
									// onClick={handleCLick}
									// disabled={loader}
									onClick={() => setSearchInput(userSearch)}
								>
									search
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="People__table--body mt-24">
					{submitingFrom ? (
						<Spinner />
					) : (
						<ClientsTable
							setOpenSideModal={setOpenSideModal}
							data={resellersClients?.content}
							setSelectedClient={setSelectedClient}
							handleClearFilter={handleClearFilter}
						/>
					)}
					{resellersClients?.content?.length > 0 && (
						<PaginationWithApi
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							totalPages={resellersClients?.totalPages}
							handleNext={handleNext}
							handlePrev={handlePrev}
						/>
					)}
				</div>
			</div>
			{openSideModal && (
				<ClientsDetailSideModal
					openSideModal={openSideModal}
					setOpenSideModal={setOpenSideModal}
					selectedClient={selectedClient}
				/>
			)}
		</div>
	);
};

export default People;
