import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';

import cancelIcon from '../assets/svgs/cancel.svg';
import { navigations } from '../utils/navigations';
import Logo from '../assets/svgs/logo.svg';

const SidebarSlide = ({
	setIsSidebarOpen,
	isSidebarOpen,
	splitLocation,
	// active,
	// setActive,
}) => {
	const closeSidebar = () => {
		setIsSidebarOpen(false);
	};
	const changeRoute = () => {
		// setActive(link);
		closeSidebar();
	};

	return (
		<div className={`${isSidebarOpen ? 'backdrop' : ''} sidebarContainer `}>
			<aside
				className={`${
					isSidebarOpen ? 'sidebar-slide show-sidebar ' : 'sidebar-slide '
				}`}
			>
				<div className="sidebar__container ">
					<div className="sidebar__header">
						<div className="layout__sidenav--banner">
							<img src={Logo} alt="fla" />
						</div>
						{/* <button className="close-side" type="button" onClick={closeSidebar}> */}
						{/* <Icon id={'close'} width={'20'} height={'20'} /> */}
						<span className="close-side" onClick={closeSidebar}>
							<img src={cancelIcon} alt="fla" />
						</span>
						{/* </button> */}
					</div>
					<div className="sidebar__links">
						<ul>
							{navigations.map((navigation) => {
								return (
									<Link
										to={navigation.link}
										onClick={() => changeRoute()}
										className={`navlinks ${
											splitLocation[1] === navigation.link ? 'active' : ''
										}`}
										key={navigation.id}
									>
										<Icon
											width="16px"
											height="16px"
											id={`${
												splitLocation[1] === navigation?.link
													? navigation?.coloredIcon
													: navigation?.icon
											}`}
										/>
										<p>{navigation?.name}</p>
									</Link>
								);
							})}
						</ul>
					</div>
				</div>
			</aside>
		</div>
	);
};

export default SidebarSlide;
