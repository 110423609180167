import React, { useState } from 'react';
import Icon from './Icon';

const PaginationWithApi = ({
	handlePrev,
	handleNext,
	setCurrentPage,
	currentPage,
	totalPages,
	paginationWithSlice = false,
}) => {
	const [showNumbers, setShowNumbers] = useState(false);
	const allNumbers = Array.from({ length: totalPages }, (_, i) => i);
	return (
		<div className="pagination">
			<div className="pagecontrols">
				<div
					className="container showmore--container"
					onClick={() => setShowNumbers(true)}
					onMouseLeave={() => setShowNumbers(false)}
				>
					<p>Show more</p>
					<Icon width="9" height="14" id="left-caret" />
					{showNumbers && (
						<div className="showOptions">
							<div className="showOption--numbers">
								{paginationWithSlice ? (
									<>
										{' '}
										{[...Array(totalPages)].map((_, index) => (
											<div
												key={index}
												onClick={() => setCurrentPage(index + 1)}
												className={`numbers ${
													currentPage === index + 1 ? 'active' : ''
												}`}
											>
												{index + 1}
											</div>
										))}
									</>
								) : (
									<>
										{' '}
										{allNumbers.map((num) => (
											<div
												className={`numbers ${
													currentPage === num ? 'active' : ''
												}`}
												key={num}
												onClick={() => setCurrentPage(num)}
											>
												{num + 1}
											</div>
										))}
									</>
								)}
							</div>
						</div>
					)}
				</div>
				<div className="container">
					{paginationWithSlice ? (
						<p className="pageNumber">
							{`page ${currentPage || 1} of ${totalPages || '1'}`}
						</p>
					) : (
						<p className="pageNumber">
							{`page ${currentPage ? currentPage + 1 : 1} of ${
								totalPages ? totalPages : '1'
							}`}
						</p>
					)}
				</div>
			</div>

			<div className="pages">
				<div className="next container" onClick={handlePrev}>
					<Icon
						width="12"
						height="12"
						id="arrowForward"
						className={'prevSvg'}
					/>
					<span>Previous</span>
				</div>

				<div className="next container" onClick={handleNext}>
					<span>NEXT</span>
					<Icon width="12" height="12" id="arrowForward" />
				</div>
			</div>
		</div>
	);
};

export default PaginationWithApi;
