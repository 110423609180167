import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import DashboardHeader from '../components/DashboardHeader';
import DashboardSIdebar from '../components/DashboardSIdebar';
import SidebarSlide from '../components/SlidingSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { getBusinessDetails } from '../features/user/userSlice';
import Spinner from '../components/PageLoader';

const DashboardLayout = () => {
	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split('/');
	const { isLoading } = useSelector((store) => store.user);
	const dispatch = useDispatch();
	const [slidingSidebar, setSlidingSidebar] = useState(false);

	useEffect(() => {
		dispatch(getBusinessDetails());
	}, []);

	return (
		<>
			{isLoading ? (
				<Spinner />
			) : (
				<>
					<SidebarSlide
						setIsSidebarOpen={setSlidingSidebar}
						isSidebarOpen={slidingSidebar}
						// setActive={setActive}
						splitLocation={splitLocation}
					/>
					<div className="layout">
						<DashboardSIdebar
							// active={active}
							// setActive={setActive}
							slidingSidebar={slidingSidebar}
							setSlidingSidebar={setSlidingSidebar}
							splitLocation={splitLocation}
						/>
						<div className="layout__main">
							{/* <div className="dashboard__main--body"> */}
							<DashboardHeader
								// active={active}
								splitLocation={splitLocation}
								setSlidingSidebar={setSlidingSidebar}
								slidingSidebar={slidingSidebar}
							/>
							<div className="container">
								<Outlet />
							</div>
							{/* </div> */}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default DashboardLayout;
