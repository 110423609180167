import React, { useEffect } from 'react';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';

const Validation = () => {
	const navigate = useNavigate();
	const clientUploadData = localStorage.getItem('clientUploadData');
	const error = JSON.parse(clientUploadData);
	useEffect(() => {
		if (!clientUploadData) {
			navigate('/add-clients');
		}
	}, []);
	return (
		<div className="validation">
			<div className="validation__header">
				<h1>Validated File Information</h1>{' '}
			</div>
			<div className="validation__body">
				<div className="validation__body--left card">
					<Icon id={'successful-upload'} height={'75'} width={'61'} />
					<div className="validation__details">
						<h4>{error?.data?.totalCount - error?.data?.errorCount}</h4>
						<p>Total records detected in the uploaded file</p>
					</div>
				</div>
				<div className="validation__body--right card">
					<Icon id={'error-upload'} height={'75'} width={'61'} />
					<div className="validation__details">
						<h4>{error?.data?.errorCount}</h4>
						<p>
							Total records with errors found - passes will not be assigned.
							Please review
						</p>
					</div>
				</div>
			</div>
			{!error?.data?.errorCount > 0 && (
				<div className="validation__hint">
					<span>
						<Icon id={'hintbulb'} height={'20'} width={'16'} />
					</span>
					<p>
						The file is being processed. {"You'll"} get a notification once all
						passes have been assigned.
					</p>
				</div>
			)}
			<div>
				<Button
					variant="primary"
					type="submit"
					className={'mt-24'}
					block
					// disabled={!csvFileSeled && !flancePassId}
					onClick={() => {
						if (error?.data?.errorCount > 0) {
							navigate('/validation-errors');
						} else {
							localStorage.removeItem('clientUploadData');
							navigate('/people');
						}
					}}
				>
					{error?.data?.errorCount > 0
						? 'View Errors'
						: 'Go To People’s Dashboard'}
				</Button>
			</div>
		</div>
	);
};

export default Validation;
