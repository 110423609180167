import React from 'react';
import Table from './Table';
import skeletonLoading from '../../assets/svgs/chat-thread.svg';
import { formatTierName } from '../../utils/helpers';

const GymTable = ({ data }) => {
	const columns = ['FITNESS CENTER Name', 'STATE', 'CITY', 'ADDRESS', 'TIER'];
	return (
		<div>
			{data?.length < 1 ? (
				<div className="emptyClients">
					<div className="noEmployee__container">
						<img src={skeletonLoading} alt="loading" className="mt-24" />
						<h4 className="mt-24">No Fitness Centers Found</h4>
						<p className="mt-24">
							Adjust your filters to see available options or{' '}
							<span>
								<a
									href={
										'https://docs.google.com/forms/d/e/1FAIpQLSeNm8gPz_osAbr7j_DdMqHyF43XxoGKKrC2gVjHNoQa3i_rFQ/viewform'
									}
									target="_blamk"
								>
									recommend a fitness center{' '}
								</a>
							</span>{' '}
							{"you'd"} like to see added.
						</p>
					</div>
				</div>
			) : (
				<Table columns={columns} blueHeader={true}>
					<>
						{data?.map((row) => (
							<tr className="pointer grey-bg" key={row?.id}>
								<td className="plan-table ">{row?.name}</td>
								<td className="plan-table">{row?.address?.state}</td>
								<td className="plan-table">{row?.address?.city}</td>
								<td className="plan-table address-cell">
									{row?.address?.street}
								</td>
								<td className="plan-table">{formatTierName(row?.tier)}</td>
							</tr>
						))}
					</>
				</Table>
			)}
		</div>
	);
};

export default GymTable;
