import React from 'react';
import Thead from './Thead';

const Table = ({
	columns,
	children,
	type,
	blueHeader = false,
	transparentHeader = false,
	handleCheckAll,
	checkAll,
}) => {
	return (
		<table>
			<Thead
				type={type}
				columns={columns}
				blueHeader={blueHeader}
				transparentHeader={transparentHeader}
				handleCheckAll={handleCheckAll}
				checkAll={checkAll}
			/>
			{children}
		</table>
	);
};

export default Table;
