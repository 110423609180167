import React, { useEffect } from 'react';
import Input from './forms/Input';
import PhoneInput from './forms/PhoneInput';

import MultiSelectInput from './forms/MultiSelectInput';

const EditClientDetails = ({
	values,
	handleChange,
	handleBlur,
	setFieldValue,
	individualClients,
	errors,
	touched,
	setPhoneDetails,
	label,
	setLabel,
}) => {
	useEffect(() => {
		setFieldValue('firstName', individualClients?.data?.firstName);
		setFieldValue('lastName', individualClients?.data?.lastName);
		setFieldValue(
			'email',
			individualClients?.data?.email ? individualClients?.data?.email : ''
		);
		setFieldValue('phone', individualClients?.data?.phone);
		setFieldValue('clientId', individualClients?.data?.clientId);
		setFieldValue('label', individualClients?.data?.label);
		setLabel(individualClients?.data?.label);
	}, []);

	return (
		<div className="editClient">
			<div>
				<div className="inputgroup">
					<Input
						type="text"
						id="firstName"
						label="First name"
						name="firstName"
						value={values.firstName}
						onChange={handleChange}
						onBlur={handleBlur}
						placeholder="First name"
					/>
					<Input
						type="text"
						id="lastName"
						label="Last name"
						name="lastName"
						value={values.lastName}
						onChange={handleChange}
						onBlur={handleBlur}
						placeholder="Last name"
					/>
				</div>
				<div className="inputgroup">
					<Input
						type="text"
						id="clientId"
						label="Client Id"
						name="clientId"
						value={values.clientId}
						onChange={handleChange}
						onBlur={handleBlur}
						placeholder="Client Id"
					/>
					<Input
						type="email"
						id="email"
						label="email"
						name="email"
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
						placeholder="email"
						error={
							touched.email && errors.email ? (
								<small className="error">{errors.email}</small>
							) : null
						}
					/>
				</div>
				{/* <Input
					type="tel"
					id="phone"
					label="Phone Number"
					name="phone"
					value={values.phone}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="Phone Number"
					error={
						touched.phone && errors.phone ? (
							<small className="error">{errors.phone}</small>
						) : null
					}
				/> */}{' '}
				<div className="phoneInput">
					<PhoneInput setPhoneDetails={setPhoneDetails} />
				</div>
				<MultiSelectInput
					labels={label}
					onChange={setLabel}
					placeholder="Type and press Enter to add Labels"
					className="mt-30"
				/>
			</div>
		</div>
	);
};

export default EditClientDetails;
