import React, { useRef, useState } from 'react';
import Icon from './Icon';
import { limitString } from '../utils/helpers';
import ClientCheckBox from './ClientCheckBox';
import Button from './Button';
import { submitClientsWithCSV } from '../features/resellers/resellersSlice';
import { useDispatch, useSelector } from 'react-redux';
import Select from './forms/Select';
import { useNavigate } from 'react-router-dom';

const ImportClients = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { resellerPasses, submitingFrom } = useSelector(
		(store) => store.resellers
	);
	const [csvFileSeled, setCsvFileSeled] = useState();
	const [flancePassId, setFlancePassId] = useState('');
	const [checkboxValues, setCheckboxValues] = useState({
		useClientIdAsPassCode: false,
		sendPassCodeViaEmail: false,
	});
	const [csvSucces, setCsvSuccess] = useState(false);
	const inputFile = useRef(null);
	const handleChange = (e) => {
		const { name, checked } = e.target;
		setCheckboxValues((prevValues) => ({
			...prevValues,
			[name]: checked,
		}));
	};
	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		setCsvFileSeled(file);
		// setUploadingFile(true);
		setTimeout(() => {
			setCsvSuccess(true);
		}, 1000);
	};
	const handleSubmit = () => {
		const formData = new FormData();
		formData.append('file', csvFileSeled);
		formData.append('flancePassId', flancePassId);
		formData.append(
			'useClientIdAsPassCode',
			checkboxValues.useClientIdAsPassCode
		);
		formData.append(
			'sendPassCodeViaEmail',
			checkboxValues.sendPassCodeViaEmail
		);
		dispatch(submitClientsWithCSV(formData)).then((res) => {
			if (res.type === 'pass/submitClientsWithCSV/fulfilled') {
				// setShowErrorBanner(false);
				handleReset();

				// setUploadingFile(false);
				navigate('/validation');
			} else {
				if (res?.payload?.data) {
					handleReset();
					navigate('/validation');
				}
			}
		});
	};
	const handleReset = () => {
		if (inputFile.current) {
			inputFile.current.value = '';
			inputFile.current.type = 'text';
			inputFile.current.type = 'file';
		}
	};
	// const uploadingCsv = false;
	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};
	return (
		<div className="addClients--header__info">
			{/* {showErrorBanner && (
				<div className="clients__error">
					<h4>Steps to Fix Errors:</h4>

					<div className="clients__error--list">
						<ul>
							<li>1. Open the CSV file and find invalid data.</li>
							<li>2. Update data, save and re-upload the file.</li>
						</ul>
						<ul className="formats">
							<li className="file--deets__size">
								For numeric fields, Replace text with numbers
							</li>
							<li className="file--deets__size">
								For email format “username@domain.com”
							</li>
						</ul>
					</div>
				</div>
			)} */}
			<div className="add--image">
				{csvSucces ? (
					<>
						{submitingFrom ? (
							<div className="big-loader"></div>
						) : (
							<>
								{' '}
								<Icon id={'bigBlueCheck'} height={'66'} width={'64'} />
							</>
						)}

						<p className="up-success">
							{submitingFrom ? 'Validating' : 'File Uploaded Successfully!'}{' '}
						</p>
						<div className="changeFile">
							<div className="file--deets">
								<span>
									<Icon id={'template'} height={'27'} width={'18'} />
								</span>
								<p className="file--deets__name">
									{limitString(csvFileSeled?.name, 10)}...csv{' '}
								</p>
								<li className="file--deets__size">
									{formatBytes(csvFileSeled?.size)}
								</li>
							</div>
							<div className="action--btn">
								<div className="upload__btn">
									<span>Change File</span>
									<input
										type="file"
										id="file"
										accept=".csv"
										className="file-input"
										onChange={(e) => {
											setCsvFileSeled(null);
											handleFileUpload(e);
										}}
										disabled={submitingFrom}
										ref={inputFile}
									/>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<span className="add--image__container"></span>

						<Icon id={'csvFile'} height={'45'} width={'32'} />

						<div className="image--text">
							<p>
								<>
									Drag and drop file here or <span>Choose file</span>
								</>
							</p>
						</div>
						<input
							type="file"
							id="file"
							accept=".csv"
							className="file-input"
							onChange={handleFileUpload}
							disabled={submitingFrom}
							ref={inputFile}
						/>
					</>
				)}
			</div>
			<p className="mt-16 csv--type">
				File Include: first & last name, phone number, email, expiry date, and
				Client ID
			</p>
			<div className="template">
				<div className="template--text">
					<h3>
						<span>
							<Icon id={'template'} height={'27'} width={'18'} />
						</span>
						CSV Template{' '}
					</h3>
					<p>You can download this template as a sample for your file.</p>
				</div>
				<div className="template--btn">
					<a
						href={
							'https://docs.google.com/spreadsheets/d/17eX9SKUL1vm-koDf5Mah-L62uwcigjWf2qOjIj6lL2c/edit?gid=0#gid=0'
						}
						target="_blamk"
					>
						<button>
							<span>Download</span>
						</button>
					</a>
				</div>
			</div>

			<div className="mt-24">
				<Select
					options={resellerPasses?.map((item) => ({
						name: item.name,
						value: item.id,
					}))}
					onChange={(e) => setFlancePassId(e.target.value)}
					value={flancePassId}
					name="flancePassId"
					id="flancePassId"
					placeholder="Assign Pass"
				/>
			</div>

			<div className="mt-24">
				<ClientCheckBox
					handleChange={handleChange}
					sendPassCodeViaEmail={checkboxValues?.sendPassCodeViaEmail}
					useClientIdAsPassCode={checkboxValues?.useClientIdAsPassCode}
				/>
			</div>

			<Button
				variant="primary"
				type="submit"
				className={'mt-24'}
				block
				disabled={!csvFileSeled && !flancePassId}
				onClick={handleSubmit}
			>
				{submitingFrom ? 'Validating...' : 'Submit'}
			</Button>
		</div>
	);
};

export default ImportClients;
