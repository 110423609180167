import React, { useEffect } from 'react';
import Icon from '../../../components/Icon';
import { ErrorTable } from '../../../components/table/ErrorTable';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';

const ValidationErrors = () => {
	const clientUploadData = localStorage.getItem('clientUploadData');
	const error = JSON.parse(clientUploadData);
	const navigate = useNavigate();
	useEffect(() => {
		if (!clientUploadData) {
			navigate('/add-clients');
		}
	}, []);
	const handleExportToExcel = async () => {
		try {
			if (!error?.data?.errorCount) {
				toast.error('No gyms to export');
				return;
			}

			// Format data for Excel
			const excelData = error?.data?.faultyClients?.map((row) => ({
				CallingCode: row?.callingCode,
				Phone: row?.phone,
				Email: row?.email,
				ClientId: row?.clientId,
				FirstName: row?.firstName,
				LastName: row?.tier,
			}));

			const worksheet = XLSX.utils.json_to_sheet(excelData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Gyns');

			// Generate filename with date
			const today = new Date().toISOString().slice(0, 10);
			const fileName = `CSV_ERROR_${today}.xlsx`;

			// Save the file
			XLSX.writeFile(workbook, fileName);
			toast.success('CSV error successfully');
		} catch (error) {
			console.error('Export error:', error);
			toast.error('Failed to export csv');
		}
	};
	return (
		<div className="validation">
			<div className="validation__header">
				<h1>FILE ERRORS</h1>
				<div className="validation__header--body">
					<div className="__left">
						<h2>The following colors indicates the type of error:</h2>
						<ul>
							<li>
								<span>
									{' '}
									<Icon id={'error-red'} height={'16'} width={'16'} />
								</span>
								Red for mandatory field errors: Phone number, First & Last name,
								Calling code, Expiry date
							</li>
							<li>
								<span>
									{' '}
									<Icon id={'warning-yellow'} height={'16'} width={'16'} />
								</span>
								Yellow for non-mandatory field errors: - Email address, Client
								ID
							</li>
						</ul>
					</div>
					<div className="__right">
						<div className="__right--header">
							<h2>
								<span>
									{' '}
									<Icon id={'error-red'} height={'16'} width={'16'} />
								</span>
								Steps to Fix Errors:
							</h2>
							<p
								onClick={() => {
									localStorage.removeItem('clientUploadData');
									navigate('/add-clients');
								}}
							>
								Upload Corrected File{' '}
							</p>
						</div>
						<ol>
							<li>
								<span className="title">Export Errors:</span> Click the{' '}
								<span>{`"EXPORT ERRORS BUTTON"`} </span> below to
								download/export the file containing the records with errors.
							</li>
							<li>
								<span className="title">Correct Errors:</span> Correct the
								errors in the downloaded file and save it on your computer
							</li>
							<li>
								<span className="title">Upload the Corrected File:</span> Once
								the errors are corrected, re-upload the file..
							</li>
						</ol>
					</div>
				</div>
			</div>
			<div className="validation__table">
				<p>
					<span>
						<Icon id={'error-red'} height={'16'} width={'16'} />
					</span>
					{error?.data?.errorCount} ROWS CONTAIN ERRORS
				</p>
				<div className="error__table mt-24">
					<ErrorTable data={error?.data?.faultyClients} />
				</div>
				<div className="validation__btns">
					<Button
						variant="secondary"
						type="submit"
						block
						onClick={() => {
							localStorage.removeItem('clientUploadData');
							navigate('/add-clients');
						}}
					>
						back
					</Button>
					<Button
						variant="primary"
						type="submit"
						block
						// disabled={!csvFileSeled && !flancePassId}
						onClick={() => handleExportToExcel()}
					>
						export Errors
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ValidationErrors;
