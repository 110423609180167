import React from 'react';
import Table from './Table';

export const ErrorTable = ({ data }) => {
	const columns = [
		'First Name',
		'Last Name',
		'CALLING CODE',
		'Email',
		'Phone number',
		'clientId',
	];
	return (
		<Table columns={columns} blueHeader={true} transparentHeader={true}>
			{data?.map((row, index) => (
				<tr className="pointer grey-bg" key={index}>
					<td className={`error-table`}>
						<div
							className={` ${
								row?.firstName?.includes('Required') ? 'errText' : ''
							}`}
						>
							{row?.firstName}
						</div>
					</td>
					<td className={`error-table `}>
						<div
							className={` ${
								row?.lastName?.includes('Required') ? 'errText' : ''
							}`}
						>
							{row?.lastName}
						</div>
					</td>
					<td className={`error-table  `}>
						<div
							className={` ${
								row?.callingCode?.includes('Required') ? 'errText' : ''
							}`}
						>
							{row?.callingCode || '--'}
						</div>
					</td>
					<td className={`error-table  `}>
						<div
							className={` ${
								row?.email?.includes('Invalid') ? 'warningText' : ''
							}`}
						>
							{row?.email || '--'}
						</div>
					</td>
					<td className={`error-table`}>
						<div
							className={` ${row?.phone?.includes('Phone') ? 'errText' : ''}`}
						>
							{row?.phone || '--'}
						</div>
					</td>
					<td className={`error-table  `}>
						<div
							className={`${row?.clientId?.includes('Phone') ? 'errText' : ''}`}
						>
							{row?.clientId || '--'}
						</div>
					</td>
				</tr>
			))}
		</Table>
	);
};
